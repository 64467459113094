/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  Bar,
  BarChart,
  // Cell,
  LabelList,
  // Legend,
  // Pie,
  // PieChart,
  ResponsiveContainer,
  // Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useAuthContext } from "../../context/auth";
import { getCategorias, getOportunidades } from "../../utils";
import "./styles.scss";

export const VentasDashboard = () => {
  const [oportunidades, setOportunidades] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [filtroMes, setFiltroMes] = useState([]);
  const [filtroEstatus, setFiltroEstatus] = useState([]);
  const [filtroIntermediario, setFiltroIntermediario] = useState([]);
  const [filtroCategoria, setFiltroCategoria] = useState([]);
  const [filtroAno, setFiltroAno] = useState(["2025"]);
  const [filtroPais] = useState("");

  const { pais: paisContext } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      })[pais] || "$",
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newOportunidades = await getOportunidades({ pais: filtroPais || paisContext });
        setOportunidades(newOportunidades);

        const newCategorias = await getCategorias();
        setCategorias(newCategorias.sort((a, b) => a.nombre.localeCompare(b.nombre)));

        setFiltroAno(["2025"]);
      } catch (error) {
        console.warn("Error fetching data:", error);
      }
    };

    fetchData();
  }, [filtroPais, paisContext]);

  const oportunidadesFiltradas = oportunidades.filter((oportunidad) => {
    const mesMatch = filtroMes.length === 0 || filtroMes.includes(oportunidad.mes);
    const estatusMatch = filtroEstatus.length === 0 || filtroEstatus.includes(oportunidad.estatus);
    const intermediarioMatch =
      filtroIntermediario.length === 0 || filtroIntermediario.includes(oportunidad.intermediario);
    const categoriaMatch = filtroCategoria.length === 0 || filtroCategoria.includes(oportunidad.categoria);
    const anoMatch = filtroAno.length > 0 ? filtroAno.includes(oportunidad.ano?.toString() || "2024") : true;
    const paisMatch = filtroPais ? oportunidad.pais === filtroPais : true;

    return (
      mesMatch &&
      estatusMatch &&
      intermediarioMatch &&
      categoriaMatch &&
      anoMatch &&
      paisMatch &&
      !oportunidad.eliminada
    );
  });

  // const calcularResumen = (oportunidades) => {
  //   const totalVentas = oportunidades.reduce((acc, op) => acc + op.importe, 0);
  //   const totalOportunidades = oportunidades.length;
  //   const categoriasConVentas = oportunidades.reduce((acc, op) => {
  //     acc[op.categoria] = (acc[op.categoria] || 0) + op.importe;
  //     return acc;
  //   }, {});

  //   const topCategoria = Object.keys(categoriasConVentas).reduce(
  //     (a, b) => (categoriasConVentas[a] > categoriasConVentas[b] ? a : b),
  //     ""
  //   );
  //   const clientesClaves = [...new Set(oportunidades.map((op) => op.cliente))].slice(0, 3);

  //   return {
  //     totalVentas,
  //     totalOportunidades,
  //     topCategoria: topCategoria || "-",
  //     clientesClaves: clientesClaves.length > 0 ? clientesClaves : ["-"],
  //   };
  // };

  // const resumenData = calcularResumen(oportunidadesFiltradas);

  const renderDropdownOptions = (options, selectedValues, setFilter) =>
    options.map((option, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`${option}${index}`}
          value={option}
          checked={selectedValues.includes(option)}
          onChange={handleFilterChange(setFilter)}
          style={{ marginLeft: "-15px" }}
        />
        <label className="form-check-label" htmlFor={`${option}${index}`} style={{ marginLeft: "10px" }}>
          {option}
        </label>
      </div>
    ));

  const handleFilterChange = (setFilter) => (e) => {
    const { value, checked } = e.target;
    setFilter((prev) => (checked ? [...prev, value] : prev.filter((item) => item !== value)));
  };

  const ventasPorCategoria = categorias.map((categoria) => {
    const ventas100 = oportunidadesFiltradas
      .filter((op) => op.categoria === categoria.nombre && op.estatus === "100%")
      .reduce((acc, op) => acc + op.importe, 0);

    const ventas90 = oportunidadesFiltradas
      .filter((op) => op.categoria === categoria.nombre && op.estatus === "90%")
      .reduce((acc, op) => acc + op.importe, 0);

    const ventas75 = oportunidadesFiltradas
      .filter((op) => op.categoria === categoria.nombre && op.estatus === "75%")
      .reduce((acc, op) => acc + op.importe, 0);

    const estatusRelevantes = filtroEstatus.length > 0 ? filtroEstatus : ["100%", "90%", "75%"];

    const clientes = [
      ...new Set(
        oportunidadesFiltradas
          .filter((op) => op.categoria === categoria.nombre && estatusRelevantes.includes(op.estatus))
          .map((op) => op.cliente)
      ),
    ];

    return {
      name: categoria.nombre,
      ventas100,
      ventas90,
      ventas75,
      clientes,
    };
  });

  const getRadius = (key, data) => {
    const keys = Object.keys(data).filter((key) => data[key] > 0);
    const firstKey = keys[0];
    const lastKey = keys.at(-1);

    if (firstKey === key && lastKey === key) {
      return [10, 10, 10, 10];
    } else if (firstKey === key) {
      return [10, 0, 0, 10];
    } else if (lastKey === key) {
      return [0, 10, 10, 0];
    }

    return [0, 0, 0, 0];
  };

  const renderBarChart = (categoriaData) => {
    const totalVentas = categoriaData.ventas100 + categoriaData.ventas90 + categoriaData.ventas75;

    return (
      <div
        key={categoriaData.name}
        style={{ width: "80%", margin: "0 auto", borderBottom: "1px solid #e0e0e0" }}
        className="d-flex align-items-center"
      >
        <div style={{ flexBasis: "20%", textAlign: "left", display: "flex", alignItems: "center" }}>
          <h6 style={{ margin: 0 }}>{categoriaData.name}</h6>
        </div>
        <div style={{ flexBasis: "25%", textAlign: "left", display: "flex", alignItems: "center" }}>
          <h6 style={{ margin: 0 }}>
            {categoriaData.clientes.length > 0
              ? categoriaData.clientes.join(", ").length > 30
                ? `${categoriaData.clientes.length} clientes`
                : categoriaData.clientes.join(", ")
              : "-"}
          </h6>
        </div>
        <div style={{ flexBasis: "55%", height: "80px", position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -35%)",
              width: "100%",
              height: "100%",
            }}
          >
            <ResponsiveContainer>
              {totalVentas > 0 ? (
                <BarChart layout="vertical" data={[categoriaData]} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                  <XAxis
                    type="number"
                    domain={[
                      0,
                      Math.max(100, categoriaData.ventas100 + categoriaData.ventas90 + categoriaData.ventas75),
                    ]}
                    tick={false}
                    axisLine={false}
                  />
                  <YAxis type="category" dataKey="name" hide />
                  <Bar dataKey="ventas100" stackId="a" fill="#198754" radius={getRadius("ventas100", categoriaData)}>
                    <LabelList
                      dataKey="ventas100"
                      position="center"
                      formatter={(value) =>
                        value > 0
                          ? `${moneda(filtroPais || paisContext)} ${value.toLocaleString(
                              localString(filtroPais || paisContext)
                            )}`
                          : ""
                      }
                      fill="#fff"
                    />
                  </Bar>
                  <Bar dataKey="ventas90" stackId="a" fill="#dc3545" radius={getRadius("ventas90", categoriaData)}>
                    <LabelList
                      dataKey="ventas90"
                      position="center"
                      formatter={(value) =>
                        value > 0
                          ? `${moneda(filtroPais || paisContext)} ${value.toLocaleString(
                              localString(filtroPais || paisContext)
                            )}`
                          : ""
                      }
                      fill="#fff"
                    />
                  </Bar>
                  <Bar dataKey="ventas75" stackId="a" fill="#0d6efd" radius={getRadius("ventas75", categoriaData)}>
                    <LabelList
                      dataKey="ventas75"
                      position="center"
                      formatter={(value) =>
                        value > 0
                          ? `${moneda(filtroPais || paisContext)} ${value.toLocaleString(
                              localString(filtroPais || paisContext)
                            )}`
                          : ""
                      }
                      fill="#fff"
                    />
                  </Bar>
                </BarChart>
              ) : (
                <BarChart
                  layout="vertical"
                  data={[{ name: "Sin datos", empty: 100 }]}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <XAxis type="number" domain={[0, 100]} tick={false} axisLine={false} />
                  <YAxis type="category" dataKey="name" hide />
                  <Bar dataKey="empty" fill="#000" radius={[10, 10, 10, 10]}>
                    <LabelList
                      dataKey="empty"
                      position="center"
                      formatter={() => "Sin ventas ni oportunidades mapeadas"}
                      fill="#fff"
                    />
                  </Bar>
                </BarChart>
              )}
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  };

  const resumenOportunidades = {
    "100%": oportunidadesFiltradas.filter((op) => op.estatus === "100%").length,
    "90%": oportunidadesFiltradas.filter((op) => op.estatus === "90%").length,
    "75%": oportunidadesFiltradas.filter((op) => op.estatus === "75%").length,
    "50%": oportunidadesFiltradas.filter((op) => op.estatus === "50%").length,
    "0%": oportunidadesFiltradas.filter((op) => op.estatus === "0%").length,
  };

  const resumenMontos = {
    "100%": oportunidadesFiltradas.filter((op) => op.estatus === "100%").reduce((acc, op) => acc + op.importe, 0),
    "90%": oportunidadesFiltradas.filter((op) => op.estatus === "90%").reduce((acc, op) => acc + op.importe, 0),
    "75%": oportunidadesFiltradas.filter((op) => op.estatus === "75%").reduce((acc, op) => acc + op.importe, 0),
    "50%": oportunidadesFiltradas.filter((op) => op.estatus === "50%").reduce((acc, op) => acc + op.importe, 0),
    "0%": oportunidadesFiltradas.filter((op) => op.estatus === "0%").reduce((acc, op) => acc + op.importe, 0),
  };

  const renderResumen = () => (
    <div className="row justify-content-center mt-3 mb-4">
      <div className="col-md-7">
        <div className="d-flex justify-content-between">
          {Object.entries(resumenOportunidades).map(([estatus, count]) => (
            <div
              key={estatus}
              className={`text-center ${getStatusClass(estatus)} p-3 rounded`}
              style={{
                flex: "1",
                margin: "10px", // Ajusta este valor si es necesario
              }}
            >
              <div style={{ fontSize: "1.5rem", marginBottom: "3px" }}>{estatus}</div>
              <div style={{ fontSize: "1rem", marginBottom: "2px" }}>{count} oportunidades</div>
              <div style={{ fontSize: "1rem" }}>
                {moneda(filtroPais || paisContext)}{" "}
                {resumenMontos[estatus].toLocaleString(localString(filtroPais || paisContext))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const getStatusClass = (estatus) => {
    switch (estatus) {
      case "100%":
        return "bg-success text-white";
      case "90%":
        return "bg-danger text-white";
      case "75%":
        return "bg-primary text-white";
      case "50%":
        return "bg-warning text-dark";
      case "0%":
        return "bg-black text-white";
      default:
        return "";
    }
  };

  // const COLORS = ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#33FFF6"];

  // const renderTopCategoriesChart = () => {
  //   const ventasPorCategoria100 = oportunidadesFiltradas
  //     .filter((op) => op.estatus === "100%")
  //     .reduce((acc, op) => {
  //       acc[op.categoria] = (acc[op.categoria] || 0) + op.importe;
  //       return acc;
  //     }, {});

  //   const sortedCategories = Object.entries(ventasPorCategoria100)
  //     .map(([name, value]) => ({ name, value }))
  //     .sort((a, b) => b.value - a.value)
  //     .slice(0, 5);

  //   return (
  //     <div className="row justify-content-center mt-4 mb-4">
  //       <div style={{ width: 500 }}>
  //         <div style={{ width: 210, display: "flex", justifyContent: "center", alignItems: "center" }}>
  //           <ResponsiveContainer height={250}>
  //             <PieChart>
  //               <Pie data={sortedCategories} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100}>
  //                 {sortedCategories.map((entry, index) => (
  //                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
  //                 ))}
  //               </Pie>
  //               <Tooltip
  //                 formatter={(value) =>
  //                   `${moneda(filtroPais || paisContext)} ${value.toLocaleString(localString(filtroPais || paisContext))}`
  //                 }
  //               />
  //             </PieChart>
  //           </ResponsiveContainer>
  //         </div>
  //         <div>
  //           <ul className="list-unstyled">
  //             {sortedCategories.map((entry, index) => (
  //               <li key={`legend-${index}`} style={{ display: "flex", alignItems: "center", marginBottom: "2px" }}>
  //                 <div
  //                   style={{
  //                     width: "10px",
  //                     height: "10px",
  //                     backgroundColor: COLORS[index % COLORS.length],
  //                     marginRight: "10px",
  //                   }}
  //                 />
  //                 <span>{entry.name}</span>
  //               </li>
  //             ))}
  //           </ul>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const handleAnoChange = (e) => {
    const value = e.target.value;
    setFiltroAno((prev) => (prev.includes(value) ? prev.filter((ano) => ano !== value) : [...prev, value]));
  };

  return (
    <div>
      {/* Resumen de oportunidades */}
      {renderResumen()}

      {/* Gráfico circular del top 5 de categorías */}
      {/* {renderTopCategoriesChart()} */}

      <div className="row justify-content-center mt-2 mb-5">
        {/* Filtro por mes */}
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle
              variant="white border"
              id="dropdown-mes"
              className={`form-control text-start ${filtroMes.length > 0 ? "active-filter" : ""}`}
            >
              {filtroMes.length > 0 ? filtroMes.map((mes) => mesEnLetras(mes)).join(", ") : "Mes/meses"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {renderDropdownOptions(
                ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
                filtroMes,
                setFiltroMes
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Filtro por estatus */}
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle
              variant="white border"
              id="dropdown-estatus"
              className={`form-control text-start ${filtroEstatus.length > 0 ? "active-filter" : ""}`}
            >
              {filtroEstatus.length > 0 ? filtroEstatus.join(", ") : "Status"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {renderDropdownOptions(["100%", "90%", "75%", "50%", "0%"], filtroEstatus, setFiltroEstatus)}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Filtro por intermediario */}
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle
              variant="white border"
              id="dropdown-intermediario"
              className={`form-control text-start ${filtroIntermediario.length > 0 ? "active-filter" : ""}`}
            >
              {filtroIntermediario.length > 0 ? filtroIntermediario.join(", ") : "Intermediario"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {renderDropdownOptions(
                [...new Set(oportunidades.map((oportunidad) => oportunidad.intermediario))].sort(),
                filtroIntermediario,
                setFiltroIntermediario
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Filtro por categoría */}
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle
              variant="white border"
              id="dropdown-categoria"
              className={`form-control text-start ${filtroCategoria.length > 0 ? "active-filter" : ""}`}
            >
              {filtroCategoria.length > 0 ? filtroCategoria.join(", ") : "Categoría"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {renderDropdownOptions(
                categorias.map((cat) => cat.nombre),
                filtroCategoria,
                setFiltroCategoria
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle variant="white border" id="dropdown-basic" className="form-control text-start">
              {filtroAno.length > 0 ? filtroAno.join(", ") : "Filtrar por año"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {["2023", "2024", "2025"].map((ano, index) => (
                <div key={index} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`ano${index}`}
                    value={ano}
                    checked={filtroAno.includes(ano)}
                    onChange={handleAnoChange}
                    style={{ marginLeft: "-15px" }} // Ajuste fino para el checkbox
                  />
                  <label className="form-check-label" htmlFor={`ano${index}`} style={{ marginLeft: "10px" }}>
                    {ano}
                  </label>
                </div>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {/* <div className="summary-container">
        <div className="summary-item">
          <strong>Total Ventas:</strong>{" "}
          {`${moneda(filtroPais || paisContext)} ${resumenData.totalVentas.toLocaleString(localString(filtroPais || paisContext))}`}
        </div>
        <div className="summary-item">
          <strong>Total Oportunidades:</strong> {resumenData.totalOportunidades}
        </div>
        <div className="summary-item">
          <strong>Top Categoría:</strong> {resumenData.topCategoria}
        </div>
        <div className="summary-item">
          <strong>Clientes Claves:</strong> {resumenData.clientesClaves.join(", ")}
        </div>
      </div> */}

      <div>
        {ventasPorCategoria.length > 0 ? (
          ventasPorCategoria.map((categoriaData) => renderBarChart(categoriaData))
        ) : (
          <p className="text-center">No hay datos disponibles para mostrar.</p>
        )}
      </div>
    </div>
  );
};

const mesEnLetras = (mes) => {
  const mesesEnLetras = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
  return mesesEnLetras[parseInt(mes) - 1] || "";
};

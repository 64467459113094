import { Container, VentasDetalleRegionReciente } from "../../components";

const VentasRegionReciente = () => (
  <div>
    <Container header titulo="OPORTUNIDADES REGIÓN">
      <VentasDetalleRegionReciente />
    </Container>
  </div>
);

export default VentasRegionReciente;
